<template>
  <div class="security" :style="{height:bodyHeight+'px'}">
    <div>
      <monitor-navigator></monitor-navigator>
    </div>
    <div class="security-body">
      <div v-if="weakPwdWarn" class="weak-pwd-warn">{{userText.VALIDATOR.WEAK_PASSWORD_TITLE}}</div>
      <a-spin :spinning="securitySpinning" style="height: 100%">
        <div class="title">
          账户安全设置:
        </div>
        <a-form
                style="padding: 50px"
                :layout="formLayout"
                :form="securityForm"
        >
          <a-form-item
                  label="当前密码"
                  placeholder="当前密码"
                  has-feedback
          >
            <a-input
                    :placeholder="userText.VALIDATOR.CURRENT_PASSWORD"
                    type="password"
                    v-decorator="[ 'oldPassword',{rules: [{ required: true, message: userText.VALIDATOR.CURRENT_PASSWORD }]}]"
            />
          </a-form-item>
          <a-form-item
                label="新密码"
                placeholder="新密码"
                has-feedback
        >
          <a-input
                  type="password"
                  :placeholder="userText.VALIDATOR.PASSWORD"
                  v-decorator="[ 'newPassword',{rules: [
                        { required: true, message: userText.VALIDATOR.PASSWORD,min:8, max:18 },
                        {validator: validateToNextPassword}
                        ]}]"
          />
        </a-form-item>
        <a-form-item
                label="确认新密码"
                placeholder="确认新密码"
                has-feedback
        >
          <a-input
                  @blur="handleConfirmBlur"
                  type="password"
                  :placeholder="userText.VALIDATOR.PASSWORD"
                  v-decorator="[ 'confirmPassword',{rules: [
                      { required: true, message: userText.VALIDATOR.PASSWORD,min:8, max:18 },
                      {validator: compareToFirstPassword}
                      ]}]"
          />
        </a-form-item>
          <a-form-item>
            <a-button
                    type="primary"
                    @click="updatePassword"
            >
              保存
            </a-button>
            <a-button
                    v-if="!weakPwdWarn"
                    style="margin-left: 10px"
                    type="danger"
                    @click="$router.push({path:'/monitorIndex'})"
            >
              返回
            </a-button>
          </a-form-item>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { userText } from '../../core/constant'
import { updateUserPassword } from '../../api/login'
import { mapGetters, mapActions} from 'vuex'
import monitorNavigator from "../../components/MonitorNavigator"
import Vue from 'vue'
import { getNoExistNavigatorBodyHeignt } from '../../core/bodyHeigntUtil'
import {validatePasswordStrength } from '../../utils/regexUtil'

export default {
  components: {monitorNavigator},
  data () {
    return {
      userText,
      securitySpinning: false,
      formLayout: 'horizontal',
      securityForm: this.$form.createForm(this),
      bodyHeight: '',
      weakPwdWarn: Vue.ls.get('stage'),
      confirmDirty: false
    }
  },
  created(){
      this.$nextTick(() => {
          // 禁用右键
          document.oncontextmenu = new Function("event.returnValue=false");
          // 禁用选择
          document.onselectstart = new Function("event.returnValue=false");
      });
  },
  beforeMount:function () {
      this.bodyHeight = this.getBodyHeignt();
  },
  mounted() {
      let that = this;
      that.$nextTick(function () {
          window.onresize = () => {
              return (() => {
                  that.bodyHeight = getNoExistNavigatorBodyHeignt();
              })()
          };
      });
  },
  methods: {
    ...mapActions(['Logout']),
    ...mapGetters(['userInfo']),
    validateToNextPassword(rule, value, callback) {
        const form = this.securityForm;
        if (value && value.length >= 8 && value.length <19) {
            if (validatePasswordStrength(value)) {
                callback(userText.VALIDATOR.WEAK_PASSWORD)
            } else {
                if (this.confirmDirty) {
                    form.validateFields(['confirmPassword'], { force: true });
                }
                callback()
            }
        }else {
            callback('')
        }
    },
    compareToFirstPassword(rule, value, callback) {
        const form = this.securityForm
        if (value && value.length >= 8 && value.length <19) {
            if (validatePasswordStrength(value)) {
                callback(userText.VALIDATOR.WEAK_PASSWORD)
            } else {
                if (value !== form.getFieldValue('newPassword')) {
                    callback(userText.VALIDATOR.CONFIRM_PASSWORD)
                } else {
                    callback()
                }
            }
        }else {
            callback('')
        }
    },
    handleConfirmBlur (e) {
        const value = e.target.value;
        this.confirmDirty = this.confirmDirty || !!value;
    },
    getBodyHeignt(){
        let winHeight;
        if (window.innerHeight){
            winHeight = window.innerHeight;
        }else if ((document.body) && (document.body.clientHeight)){
            winHeight = document.body.clientHeight;
        }
        return winHeight;
    },
    updatePassword () {
      this.securityForm.validateFields((err, values) => {
        if (err) {
          return
        }

        let param = {
          account: this.userInfo().account,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword
        }
        this.securitySpinning = true
        updateUserPassword(param).then(() => {
          this.$nextTick(() => {
            this.securityForm.setFieldsValue({
              oldPassword: '',
              newPassword: '',
              confirmPassword: ''
            })
          })
          let that = this
          this.$confirm({
              title: '密码修改成功',
              content: '请重新登录！',
              okText: '确定',
              cancelText: '',
              onOk() {
                  return that.Logout({}).then(() => {
                      that.$router.push({name: 'login'});
                  }).catch(err => {
                      that.notificationUtil.error({description: err.message})
                  })
              },
              onCancel() {
              }
          })
        }).catch(() => {
            this.notificationUtil.error({description: userText.OLD_PASSWORD_ERROR})
        }).finally(() => {
          this.securitySpinning = false
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .security {
    background-color: #070A2D
  }
  .security-body {
    padding: 150px 200px;
    margin-left: 200px;
    width: 80%;
  }
  .weak-pwd-warn {
    font-size: 20px;
    color: #f5222d;
    margin-left: 45px;
  }
  .title {
    color: #e4e3e3;
    font-size: 20px;
    margin: 20px 0 -20px 45px;
  }
  .security /deep/ .ant-form-item-label > label {
    color: #e4e3e3;
    font-size: 15px;
  }
</style>
